




















import Vue from 'vue';
import SignUpForm from '../../components/auth/SignUpForm.vue';

export default Vue.extend({
  name: 'SignUpPage',

  components: {
    SignUpForm,
  },
});
