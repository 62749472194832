



















































































































































































































import Vue from 'vue';
import { VForm } from '../../plugins/vuetifyTypes';
import { AuthService } from '../../services/auth/AuthService';
import { SignUpParameters } from '../../services/auth/models/SignUpParameters';
import { SnackbarType } from '../../services/snackbar/models/SnackbarType';
import { SnackbarService } from '../../services/snackbar/SnackbarService';
import { formValidator } from './../../utils/FormValidator';

export default Vue.extend({
  name: 'SignUpForm',

  data() {
    return {
      formValidator,
      isPasswordTooltipOpen: false,
      isLoading: false,
      isSignUpSuccessful: false,
      formData: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        company: '',
        country: '',
        address: null,
        zip: null,
        city: null,
        requestAccessTo: '',
        contactAt: '',
      } as SignUpParameters,
    };
  },

  methods: {
    onClickToggleIsPasswordTooltipOpen(): void {
      this.isPasswordTooltipOpen = !this.isPasswordTooltipOpen;
    },
    async onSubmit(): Promise<void> {
      this.isLoading = true;
      try {
        const validation = (this.$refs.form as VForm).validate();
        if (validation) {
          await AuthService.signUp(this.formData);
          this.isSignUpSuccessful = true;
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        const messageKey = error?.response?.data?.message?.messageKey;
        if (messageKey === 'USER_USERNAME_EXISTS') {
          SnackbarService.open(
            this.$t('snackbar.usernameExists'),
            SnackbarType.Error
          );
        } else {
          SnackbarService.open(
            this.$t('snackbar.somethingWentWrong'),
            SnackbarType.Error
          );
        }
        this.isLoading = false;
        this.isSignUpSuccessful = false;
      }
    },
  },
});
